//
// SIDE NAV
// this is the list of links on the left side of plickers.com
// most complexity comes with drag and drop
//

.sideNavContainer{
  position: fixed;
  top: $height-topNav;
  left: 0px;
  height: calc(100vh - #{$height-topNav});
  display: flex;
}

$sideNav-rightPadding: 3px;

.sideNav{
  margin-left: auto;
}

.sideNav .scrollbar-container{
  padding-top: 14px;

  padding-right: $sideNav-rightPadding;
  box-sizing: border-box;
  // height: 90%;
}

//

.sideNav-itemContainer{
  cursor: pointer;
}

.sideNav-item{
  height: 27px;

  font-size: 14.5px;

  font-weight: $lato-semibold;
  color: $textBlackDark;

  line-height: 1.3;

  display: flex;
  align-items: center;
  border-radius: 2px;

  box-sizing: border-box;

  background: $invisible;
  border: 1px solid $invisible;

  transition: background 250ms cubic-bezier(.08,.52,.52,1);

  user-select: none;
  cursor: pointer;

  letter-spacing: 0.008em;
}

.sideNav-itemContainer:focus{
  outline: none;
}

.sideNav-item:hover,
.sideNav-itemContainer:focus .sideNav-item{
  background: rgba(255,255,255,0.75);
  transition: background 50ms cubic-bezier(.08,.52,.52,1);
}

.sideNav-itemContainer:active .sideNav-item,
.sideNav-itemContainer.active .sideNav-item{
  font-weight: $lato-heavy;
  letter-spacing: normal;
  background: $white;
  // border: 1px solid $lineMid;
  border: 1px solid rgb(220,220,225);
  cursor: pointer;
  letter-spacing: 0.004em;
}


// LABEL

.sideNav-item-label{
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideNav-item--newSet{
  margin-bottom: 8px; // put back with discover
}


.sideNav-item--newSet .sideNav-item-label{
  font-weight: $lato-bold;
}


// ICON

.sideNav-item-icon{
  height: 100%;
  width: 28px;
  flex-shrink: 0;
  flex-grow: 0;

  box-sizing: border-box;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.sideNav-item-icon .icon{
  height: 15px;
  width: 15px;
  min-width: 15px;

  fill: $blue;
}

.is--class .sideNav-item-icon-circle{
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

// Discover icon
.sideNav-itemContainer .is--discover .sideNav-item-icon svg.discoverIcon{
  display: block;
  fill: $blue;
}

.sideNav-itemContainer .is--discover .sideNav-item-icon svg.discoverActiveIcon{
  display: none;
}

.sideNav-itemContainer.active .is--discover .sideNav-item-icon svg.discoverIcon{
  display: none;
}

.sideNav-itemContainer.active .is--discover .sideNav-item-icon svg.discoverActiveIcon{
  display: block;
}

.sideNav-itemContainer .is--discover .sideNav-item-icon svg.discoverActiveIcon #Sea{
  fill: #167BF0;
}

.sideNav-itemContainer .is--discover .sideNav-item-icon svg.discoverActiveIcon #Land{
  fill: #58DEAD;
}


//
// REPOs (aka packs)

.sideNav-item.is--repo,
.sideNav-item.is--newRepo{
  padding-left: 8px;
}

// Pending invitation

.sideNav-item--repo--isPending .sideNav-item-label{
  font-weight: $lato-heavy;
  letter-spacing: 0.004em;
}

.sideNav-item-pendingRepo{
  flex-shrink: 0;
  flex-grow: 0;

  margin-left: 2px;
  margin-right: 2px;

  height: 100%;
  width: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

}

.sideNav-item-pendingRepo-circle{
  width: 9px;
  height: 9px;
  border-radius: 4.5px;
  background: $red--notification;
}

.sideNav-item--repo--isPending:hover .sideNav-item-label,
.sideNav-itemContainer.active .sideNav-item-label{
  color: $textBlack;
}

.sideNav-itemContainer.active .sideNav-item-pendingRepo{
  background: none;
}

//

.sideNav-item.is--repo .sideNav-item-state,
.sideNav-item.is--class .sideNav-item-state {
  width: 22px;
  height: 100%;

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sideNav-item.is--repo .sideNav-item-state-pendingMarker{
  width: 9px;
  height: 9px;
  background: $green;
  border-radius: 50%;
  margin-right: 1px;
  display: none;
}

.sideNav-item.is--repo .icon.shared,
.sideNav-item.is--repo .icon.globe-shared,
.sideNav-item.is--repo .icon.globe-shared-author {
  width: 15px;
  height: 15px;
  fill: $invisible;
}

.sideNav-item.is--class .icon.organization {
  width: 17px;
  height: 17px;
  fill: $invisible;
}

.sideNav:hover .sideNav-item.is--repo .icon.shared,
.sideNav:hover .sideNav-item.is--repo .icon.globe-shared,
.sideNav:hover .sideNav-item.is--repo .icon.globe-shared-author,
.sideNav:hover .sideNav-item.is--class .icon.organization {
  fill: $gray-115;
}

.sideNav-itemContainer.active .sideNav-item.is--repo .icon.shared,
.sideNav-itemContainer.active .sideNav-item.is--repo .icon.globe-shared,
.sideNav-itemContainer.active .sideNav-item.is--repo .icon.globe-shared-author,
.sideNav-itemContainer.active .sideNav-item.is--class .icon.organization {
  fill: $gray-115;
}

.sideNav-itemContainer.active .sideNav-item.is--repo .icon.globe-shared-author{
  fill: $color-o85;
}

//
// SECTIONS (aka classes)

.is--class .sideNav-item-icon .icon.plus{
  width: 13px;
  height: 13px;

  position: absolute;
  top: 6px;
  left: 7px;
}

.sideNav-classDivider{
  margin-top: 9px;
  margin-bottom: 2px;
}

.sideNav-item.is--classes{
  padding-left: 8px;
  position: relative;
  color: lighten($textMid, 15%);
  border-color: $invisible;
  border-radius: 4px;
  margin-bottom: 0px;

  font-size: 13.5px;
  height: 20px;
}

// DRAG AND DROP ONTO CLASSES

.sideNav-item.is--class.is--dndOver{
  border: 1px solid red;
  background: $white;
  font-weight: $lato-bold;
}

.sideNav-item.is--class.is--dndOver .sideNav-item-icon-circle{
  opacity: 0;
}

.sideNav-item.is--class.isNot--dndOver .sideNav-item-icon-circle{
  opacity: 1;
}

.sideNav-item.is--class.is--dndOver .sideNav-item-icon .icon.plus{
  opacity: 1;
}

.sideNav-item.is--class.isNot--dndOver .sideNav-item-icon .icon.plus{
  opacity: 0;
}

.sideNav-item.is--class.is--dndOver.classColor--blue				{ border-color: $classColor-blue; }
.sideNav-item.is--class.is--dndOver.classColor--purple			{ border-color: $classColor-purple; }
.sideNav-item.is--class.is--dndOver.classColor--red					{ border-color: $classColor-red; }
.sideNav-item.is--class.is--dndOver.classColor--orange			{ border-color: $classColor-orange; }
.sideNav-item.is--class.is--dndOver.classColor--green				{ border-color: $classColor-green; }
.sideNav-item.is--class.is--dndOver.classColor--sky					{ border-color: $classColor-sky; }
.sideNav-item.is--class.is--dndOver.classColor--pink				{ border-color: $classColor-pink; }
.sideNav-item.is--class.is--dndOver.classColor--teal				{ border-color: $classColor-teal; }
.sideNav-item.is--class.is--dndOver.classColor--grey				{ border-color: $classColor-grey; }


// DRAG AND DROP INTO REPOS/LIBRARY
.sideNav-item--repo--isDnDOver.sideNav-item--repo--canDrop,
.sideNav-item--library--isDnDOver.sideNav-item--library--canDrop,
.sideNav-itemContainer.active .sideNav-item--library--isDnDOver.sideNav-item--library--canDrop{
  border: 1px solid $blue;
  background: $white;
}

.sideNav-item--repo--isDnDOver.sideNav-item--repo--canDrop{
  font-weight: $lato-bold;
}

.sideNav-item--repo--isDnDOver.sideNav-item--repo--canDrop .sideNav-item-state{
  opacity: 0;
}

.sideNav-item.is--repo,
.sideNav-item.is--library{
  position: relative;
}

.sideNav-item-dropIcon{
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 60px;
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 40%, rgba(255,255,255,1) 70%, rgba(255,255,255,1) 100%); // blocks out underlying text when over
  z-index: 10;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-right: 4px;
  box-sizing: border-box;
}

.sideNav-item-dropIcon .icon{
  transform: rotate(180deg);
  height: 12px;
  width: 12px;
  fill: $blue;
}

.sideNav-item--repo--isDnDOver.sideNav-item--repo--canDrop .sideNav-item-dropIcon,
.sideNav-item--library--isDnDOver.sideNav-item--library--canDrop .sideNav-item-dropIcon{
  opacity: 1;
}

//  Section coloring
.sideNav-item.classColor--blue		.sideNav-item-icon-circle		{ background: $classColor-blue; }
.sideNav-item.classColor--purple	.sideNav-item-icon-circle		{ background: $classColor-purple; }
.sideNav-item.classColor--red			.sideNav-item-icon-circle		{ background: $classColor-red; }
.sideNav-item.classColor--orange	.sideNav-item-icon-circle		{ background: $classColor-orange; }
.sideNav-item.classColor--green		.sideNav-item-icon-circle		{ background: $classColor-green; }
.sideNav-item.classColor--sky			.sideNav-item-icon-circle		{ background: $classColor-sky; }
.sideNav-item.classColor--pink		.sideNav-item-icon-circle		{ background: $classColor-pink; }
.sideNav-item.classColor--teal		.sideNav-item-icon-circle		{ background: $classColor-teal; }
.sideNav-item.classColor--grey		.sideNav-item-icon-circle		{ background: $classColor-grey; }

.sideNav-item.classColor--blue		.sideNav-item-icon .icon.plus		{ fill: $classColor-blue; }
.sideNav-item.classColor--purple	.sideNav-item-icon .icon.plus		{ fill: $classColor-purple; }
.sideNav-item.classColor--red			.sideNav-item-icon .icon.plus		{ fill: $classColor-red; }
.sideNav-item.classColor--orange	.sideNav-item-icon .icon.plus		{ fill: $classColor-orange; }
.sideNav-item.classColor--green		.sideNav-item-icon .icon.plus		{ fill: $classColor-green; }
.sideNav-item.classColor--sky			.sideNav-item-icon .icon.plus		{ fill: $classColor-sky; }
.sideNav-item.classColor--pink		.sideNav-item-icon .icon.plus		{ fill: $classColor-pink; }
.sideNav-item.classColor--teal		.sideNav-item-icon .icon.plus		{ fill: $classColor-teal; }
.sideNav-item.classColor--grey		.sideNav-item-icon .icon.plus		{ fill: $classColor-grey; }

//

.sideNav-item.is--classes .sideNav-classDivider-label{
  color: $textLight;
  opacity: 0;
  margin-left: 4px;
  transition: opacity 50ms linear;
}

.sideNav:hover
.sideNav-item.is--classes .sideNav-classDivider-label{
  opacity: 0.75;
  transition: opacity 50ms linear;
}

.sideNav-item.is--classes:hover .sideNav-classDivider-label{
  color: $textBlue;
}

.sideNav-item.is--classes:hover{
  color: $textBlack;
}

.sideNav-item.is--repos{
  pointer-events: none;
  padding-left: 8px;
  color: lighten($textMid, 15%);
  margin-bottom: 0px;

  font-size: 13.5px;
  height: 20px;
}


// NEW CLASS AND NEW REPO

.sideNav-item.is--newClass,
.sideNav-item.is--newRepo{
  opacity: 0.5;
}

.sideNav-item.is--newClass:hover,
.sideNav-item.is--newRepo:hover {
  opacity: 1;
}

.sideNav-item.is--newClass .sideNav-item-icon .icon,
.sideNav-item.is--newRepo .sideNav-item-icon .icon {
  width: 12px;
  height: 12px;
  position: relative;
  fill: $textLight;
}

.sideNav-item.is--newClass:hover .icon,
.sideNav-item.is--newRepo:hover .icon {
  fill: $blue;
}

.sideNav-item.is--newRepo.is--newRepoNoRepo{
  font-weight: $lato-bold;
  opacity: 0.8; // when no repos, make more prominent
}

.sideNav-item.is--newRepo{
  margin-bottom: 24px;
}


//// IS DRAGGING
// When dragging an item, fade out some items

.sideNav.sideNav--isDraggingSetOrQuestion,
.sideNav.sideNav--isDraggingFolder{
  .sideNav-item--newSet,
  .sideNav-item.is--reports,
  .sideNav-item.is--discover,
  .sideNav-item.is--recent,
  .sideNav-item.is--newRepo,
  .sideNav-item.is--scoresheet,
  .sideNav-item.is--newClass,
  .sideNav-item.is--library.sideNav-item--library--cannotDrop{
    opacity: 0.25;
    transition: opacity 100ms linear;
  }

}

.sideNav.sideNav--isDraggingFolder .sideNav-item.is--class,
.sideNav.sideNav--isDraggingFolder .sideNav-classDivider{
  opacity: 0.25;
  transition: opacity 100ms linear;
}

// read-only repos or not-yet-joined
.sideNav.sideNav--isDraggingSetOrQuestion .sideNav-item--repo--cannotDrop,
.sideNav.sideNav--isDraggingFolder .sideNav-item--repo--cannotDrop{
  opacity: 0.25;
  transition: opacity 100ms linear;
}

// don't reset for active repo
.sideNav.sideNav--isDraggingSetOrQuestion .sideNav-itemContainer.active .sideNav-item--repo--cannotDrop,
.sideNav.sideNav--isDraggingFolder .sideNav-itemContainer.active .sideNav-item--repo--cannotDrop{
  opacity: 1;
  transition: opacity 100ms linear;
}

// Set pending red to gray
.sideNav.sideNav--isDraggingSetOrQuestion .sideNav-item.is--repo .sideNav-item-pendingRepo .sideNav-item-pendingRepo-circle,
.sideNav.sideNav--isDraggingFolder .sideNav-item.is--repo .sideNav-item-pendingRepo .sideNav-item-pendingRepo-circle{
  background: $gray-115;
}

// SCROLLBAR TWEAKS
// we use react-perfect-scrollbar to make scrollbar small and unobtrusive

.sideNav .scrollbar-container .ps__thumb-y{
  right: 0px; // fit tight against right-side of sidenav
}

.sideNav .scrollbar-container .ps__rail-y{
  width: 9px;
}

.sideNav .scrollbar-container .ps__rail-y > .ps__thumb-y {
  background: $gray-11;
}

.sideNav .scrollbar-container .ps__rail-y:hover > .ps__thumb-y,
.sideNav .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y{
  width: 8px;
  background: $gray-8;
}

.sideNav .scrollbar-container .ps__rail-y:hover,
.sideNav .scrollbar-container .ps__rail-y.ps--clicking{
  background: rgba(0,0,40,0.02);
}


// EXPLANATION
// small label - used for new repo when no repos present
.sideNavContainer .sideNav-itemExplanation{
  font-size: 12.5px;
  font-weight: $lato-medium;
  color: $textDark;
  padding-left: 9px;
  width: 100%;
  box-sizing: border-box;

  opacity: 0;
  margin-top: -18px;
  transition: opacity 150ms linear;

  cursor: default;
  user-select: none;
}

.sideNav-item.is--newRepo.is--newRepoNoRepo:hover ~ .sideNav-itemExplanation{
  opacity: 1;
  transition: opacity 150ms linear;
  transition-delay: 100ms;
}


// REPO WARNING MESSAGE
// this is legacy and can probably be deleted (alongside react code)
// check with Nolan!

.sideNav-repoMobileUpdateWarningContainer{
  box-sizing: border-box;
  padding-left: 6px;
  margin-top: -14px;
  margin-bottom: 12px;
}

.sideNav-repoMobileUpdateWarning{
  cursor: default;
  user-select: none;

  font-size: 12.5px;

  width: 100%;

  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 3px;
  padding-bottom: 3px;

  background: #FFF7D8; // yellow
  border: 1px solid #F0C36D; // yellow
  color: #b06914; // yellow

  border-radius: 2px;
}
