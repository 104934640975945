.sideNav-footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  bottom: 0;
  right: 0;
  width: inherit;
}

.sideNav-footer-ccpaNotification {
  flex-direction: column;
  align-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.sideNav-footer-ccpaNotification-icon {
  text-align: center;
  display: inline-block;
  width: 35px;
}

.sideNav-footer-ccpaNotification-text {
  font-size: 12px;
  font-weight: 500;
  color: $textBlackDark;
  word-wrap: break-word;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.sideNav-footer-link-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  cursor: pointer;
}

.sideNav-footer-link-button-text {
  font-size: 12px;
  font-weight: 500;
  color: $textBlackDark;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
