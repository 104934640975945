//
// DROPDOWN AND CONTEXT MENUS
// These share a lot fof styling
// Dropdown menus are built ourselves
// Context menus use react-contextmenu library
//

$width-dropdownMenu: 195px;
$height-dropdownMenu-item: 30px;

.dropdownMenu,
.dropdownSubMenu,
.react-contextmenu {
	width: $width-dropdownMenu;
	background: $white;
	padding-top: 6px;
	padding-bottom: 10px;
	overflow: none;
	box-sizing: border-box;
	border: 1px solid $lineDark;
	border-radius: 5px;
	box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);
	z-index: 500;
	position: relative;
}

.dropdown{
	position: relative;
	z-index: 250;
}

.dropdownMenu{
	position: absolute;
}

.dropdownMenu,
.dropdownSubMenu,
.react-contextmenu:focus{
	outline: none;
}

//
// HIDE SHOW CONTEXT MENU

.react-contextmenu {    
  opacity: 0;
  pointer-events: none;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  background-clip: padding-box;
}

//
// DROPDOWN POSITIONING

.dropdownMenu.position--left{
	left: -145px;
}

.dropdownMenu.position--center{
	left: 0px;
}

.dropdownMenu.direction--down{
	top: 35px;
}

.dropdownMenu.direction--up{
	bottom: 35px;
}

.dropdownMenu.is--closed{
	// don't allow clicks when closed
	pointer-events: none;
}


// 
// MENU ITEM 
.dropdownMenu-item,
.react-contextmenu-item{
	width: 100%;
	background: $white;
	height: $height-dropdownMenu-item;
	box-sizing: border-box;
	padding-left: 20px;
	font-size: 15px;
	margin-bottom: 0px;

	font-weight: $lato-semibold;
	color: $textDark;
	cursor: default;	

	line-height: 1.9;
	
	user-select: none;

	display: flex;
	position: relative;
}

.dropdownMenu-item:hover,
.react-contextmenu-item:hover,
.react-contextmenu-item--active:hover {
	background: $blue-light;
	color: $white;
}

.dropdownMenu-item.is--warning:hover,
.react-contextmenu-item.is--warning:hover{
	background: $red;
}

// DISABLED STATE
.react-contextmenu-item--disabled{
	color: $textLight;
	pointer-events: none;
}

.dropdownMenu-item.smallText {
	font-size: 12.5px;
	line-height: 1.2;
  padding-right: 0.5rem;
}

.dropdownMenu-item.withIcon {
  padding-left: 5px;
}

.dropdownMenu-item.withIcon svg{
  width: 50px;
  margin-right: 5px;
}

// .dropdownMenu-item.smallText svg{
//   width: 50px;
// }

//
// SUB MENU
// e.g. add-to-queue when click on item in Library

// HEADER ITEM

.dropdownMenu-item.is--subHeader{
	position: relative;
}

.dropdownMenu-item.is--subHeader:after,
.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after{
	content: ' ';	
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'><path fill='#A9A8B7' d='M4.1582459,0.300573416 C4.54973095,0.687485215 8.37467101,4.75290404 8.37467101,4.75290404 C8.58352547,4.96058464 8.68842099,5.23085391 8.68842099,5.50112318 C8.68842099,5.77139245 8.58352547,6.04166173 8.37467101,6.2474457 C8.37467101,6.2474457 4.54973095,10.3147612 4.1582459,10.6997763 C3.76676085,11.0866881 3.06246238,11.1132409 2.64569002,10.6997763 C2.22798109,10.2882084 2.19520124,9.71258225 2.64569002,9.2071313 L6.15500696,5.50112318 L2.64569002,1.79511507 C2.19520124,1.28966412 2.22798109,0.713089672 2.64569002,0.300573416 C3.06246238,-0.112891153 3.76676085,-0.0872866959 4.1582459,0.300573416 Z' /></svg>") no-repeat;		
  	height: 11px;
  	width: 11px;
  	display: inline-block;
    position: absolute;
    right: 7px;
    top: 9.5px;
}

.dropdownMenu-item.is--subHeader:hover:after,
.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:hover:after,
.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item--active:after,
.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item--active:hover:after{
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'><path fill='#FFFFFF' d='M4.1582459,0.300573416 C4.54973095,0.687485215 8.37467101,4.75290404 8.37467101,4.75290404 C8.58352547,4.96058464 8.68842099,5.23085391 8.68842099,5.50112318 C8.68842099,5.77139245 8.58352547,6.04166173 8.37467101,6.2474457 C8.37467101,6.2474457 4.54973095,10.3147612 4.1582459,10.6997763 C3.76676085,11.0866881 3.06246238,11.1132409 2.64569002,10.6997763 C2.22798109,10.2882084 2.19520124,9.71258225 2.64569002,9.2071313 L6.15500696,5.50112318 L2.64569002,1.79511507 C2.19520124,1.28966412 2.22798109,0.713089672 2.64569002,0.300573416 C3.06246238,-0.112891153 3.76676085,-0.0872866959 4.1582459,0.300573416 Z' /></svg>") no-repeat;
}

nav.react-contextmenu-item.react-contextmenu-submenu {
	padding-left: 0px;	
}

// Submenu active state
.react-contextmenu-item--active{
	background: $blue-light;
	color: $white;
}

// Folder overflow fix
.react-contextmenu.contextMenu-subMenu--folder .react-contextmenu-item{
	white-space: nowrap;
  overflow: hidden;
}

//

.dropdownSubMenu--container{
	height: $height-dropdownMenu-item;
}

.dropdownSubMenu{
	position: relative;
	top: calc((#{$height-dropdownMenu-item} + 6px) * -1); //6px offsets top menu padding
}

.dropdownSubMenu.position--right{
	left: $width-dropdownMenu;
}

.dropdownSubMenu.position--left{
	right: $width-dropdownMenu;
}

// HOVER
.dropdownSubMenu--container:hover .dropdownMenu-item.is--subHeader{
	background: $blue-light;
	color: $white;
}

.dropdownSubMenu{
	opacity: 0;
	display: none;
}

.dropdownSubMenu--container:hover .dropdownSubMenu{
	opacity: 1;
	display: block;
}

// Section subMenu has special styling (items highlight to class color)
@import 'DropdownAndContextMenus-SectionItem'; 

//
// DROPDOWN DIVIDER
.dropdownDivider,
.react-contextmenu-item.react-contextmenu-item--divider {
	height: 5px;
	border-bottom: 1px solid $gray-125;
	width: calc(100% - 20px);
	margin-left: 20px;
	margin-bottom: 5px;
	pointer-events: none;
}

//
// DROPDOWN DIVIDER HEADER
// optional small header text

.dropdownDividerHeader{
	margin-top: 10px;

	width: 100%;
	height: 22px;
	line-height: 2;

	box-sizing: border-box;
	padding-left: 14px;

	cursor: default;
	user-select: none;

	font-size: 11px;
	color: $textLight;
	font-weight: $lato-semibold;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.dropdownDividerHeader.dropdownDividerHeader--dropdownTop{
	margin-top: 0px;
}

//
// MOVE TO FOLDER DROPDOWN

// move to folder special
.contextMenu-subMenu--folder .react-contextmenu-item:last-child{
	color: $textMid;
}

.contextMenu-subMenu--folder .react-contextmenu-item:last-child:hover{
	color: $white;
}

// 
// DROPDOWN SELECT 
// this is check mark-style, can come with text

.dropdownSelect-header{
	width: 100%;
	height: 24px;
	line-height: 2;

	box-sizing: border-box;
	padding-left: 20px;
	cursor: default;
	user-select: none;

	font-size: 12px;
	color: $textMid;
	font-weight: $lato-semibold;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.dropdownMenu-item.is--selectItem .icon.check{
	position: absolute;
	right: 10px;
	margin-top: calc((#{$height-dropdownMenu-item} - 14px)/2);
	fill: $blue;

	height: 14px;
	width: 14px;
}

.dropdownMenu-item.is--selectItem:hover .icon.check{
	fill: $white;
}

// DROPDOWN ITEM DISABLED
.dropdownMenu-item.is--disabled{
	pointer-events: none;
	opacity: 0.5;
}

//
// MULTILINE ITEMS
// used for dropdown select in repo invitation modal to set permissions

.dropdownMenu-item--multiline.dropdownMenu-item--multilineHeight--2{
	height: 48px;
}

.dropdownMenu-item--multiline.dropdownMenu-item--multilineHeight--3{
	height: 63px;
}

.dropdownMenu-item--multiline-sublabel{
	position: absolute;
	left: 20px;
	top: 25px;
	right: 16px;
	bottom: 0px;

	font-size: 12.5px;
	line-height: 1.2;
	color: transparentize($textMid, 0.1);
	font-weight: $lato-medium;
}

.dropdownMenu-item:hover
.dropdownMenu-item--multiline-sublabel{
	color: rgba(255,255,255,0.9);
}


// FIXED POSITION DROPDOWN
// used in invitation modal, yposition passed through JS and xpos in styling

.dropdownMenu.dropdownMenu--fixedPosition{
	position: fixed;
}


// 
// REFACTOR TO TOP 

.react-contextmenu-item:focus{
	outline: none;
	box-shadow: none;
}

.react-contextmenu-item:active{	
	box-shadow: none; 
	outline: none;	
}



