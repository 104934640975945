//
// COLOR VARIABLES
// we try to avoid using custom colors outside of these variables
//

//
// PRIMARY COLORS

$blue: 							rgb(22,123,240); // plickers blue
$blue-bold: 				rgb(0,114,255); // 2020 bolder blue, often used for icons and text
$blue-light: 				rgb(65,153,255); // light variant
$blue-lighter: 			rgb(219,234,254); // lightest variant
$blue-extraLight: 	lighten($blue, 45%); // often used for button backgrounds
$blue-dark: 				rgb(21,135,207);

$green: 						rgb(48,201,156);
$green-dark: 				rgb(33,176,133);

$red: 							rgb(240,108,108);
$red-dark:					rgb(237,97,97);
$red--notification: rgb(253,61,48);

$white: 						rgb(255,255,255);
$black: 						rgb(0,0,0);
$invisible: 				rgba(0,0,0,0);


//
// TEXT COLORS

$textBlackAbsolute: rgb(0,0,0);
$textBlackDark: 		rgb(10,	10,	24);
$textBlack: 				rgb(33,	33,	51);
$textDark: 					rgb(65,	65,	78);
$textMid: 					rgb(97,	97, 114);
$textLight: 				rgb(140, 139, 160);
$textBlue: $blue;
$textInputPlaceholder: rgb(204, 204, 218);


//
// LINE COLORS

$lineLight: 	rgb(235,235,240);
$lineMid: 		rgb(235,235,240);
$lineMidDark: rgb(206,206,213);
$lineDark: 		rgb(216,216,219);


//
// GRAYS
// gray-1 is darkest, gray-16 is lightest

$gray-1: 		rgb(22,	22,	29);
$gray-2: 		rgb(51,	52,	54);
$gray-3: 		rgb(70,	70,	86);
$gray-4: 		rgb(80,	81,	94);
$gray-5: 		rgb(97,	97,	114);
$gray-6: 		rgb(110,110,130);
$gray-7: 		rgb(124,123,142);
$gray-8: 		rgb(159,159,168);
$gray-9: 		rgb(170,171,178);
$gray-10: 	rgb(183,183,188);
$gray-11: 	rgb(196,195,205);
$gray-1125: rgb(204,204,216);
$gray-115: 	rgb(206,206,213);
$gray-12: 	rgb(210,214,220);
$gray-1225:	rgb(216,216,219);
$gray-125: 	rgb(225,225,230);
$gray-128: 	rgb(230,232,235);
$gray-13: 	rgb(240,241,244);
$gray-14: 	rgb(246,247,248);
$gray-15:  	rgb(250,250,253);
$gray-16: 	rgb(253,253,253);


//
// CLASS COLOR PALETTE

$classColor-blue: 	rgb(54,153,249);
$classColor-purple: rgb(181,120,221);
$classColor-red: 		rgb(243,127,130);
$classColor-orange: rgb(255,170,112);
$classColor-green:	rgb(49,201,157);
$classColor-sky:		rgb(88,199,249);
$classColor-pink:		rgb(247,145,201);
$classColor-teal:		rgb(0,191,205);
$classColor-grey:		rgb(128,129,142);

$classColor-none: 	$classColor-blue;


//
// ASSESSMENT COLORS

$color-o85: rgb(0,207,132); 	// equal or over 85%
$color-o70: rgb(108,213,133); // equal or over 70%
$color-o60: rgb(242,142,92); 	// equal or over 60%
$color-u60: rgb(243,107,100); // under 60%


//
// RICH TEXT HIGHLIGHT COLORS

// RED
$highlight--1-textColor: #EF3136;
$highlight--1-bgColor: #FEEBEB;

// ORANGE
$highlight--2-textColor: #DF6513;
$highlight--2-bgColor: #FFEEE2;

// GREEN
$highlight--3-textColor: #009D6F;
$highlight--3-bgColor: #D6F4EB;

// BLUE
$highlight--4-textColor: #0C77DE;
$highlight--4-bgColor: #EBF4FE;

// PURPLE
$highlight--5-textColor: #9935DB;
$highlight--5-bgColor: #F0E4F8;

// YELLOW
$highlight--6-textColor: #AE7E16;
$highlight--6-bgColor: #FFF2D1;

// PINK
$highlight--7-textColor: #F461B2;
$highlight--7-bgColor: #FDE1F0;

// LIGHT GRAY
$highlight--8-textColor: #ACACB5;
$highlight--8-bgColor: $white;


//
// TEMPORARY FOR STATIC UI BUILDING
// While building layouts, find it easy to use temporary color palette

$tempr: rgba(255,0,0,0.25);
$tempg: rgba(0,255,0,0.25);
$tempb: rgba(0,0,255,0.25);

$tempra: rgba(255,0,0,0.05);
$tempga: rgba(0,255,0,0.05);
$tempba: rgba(0,0,255,0.05);


//
// MISC COLORS
// Maybe can be refactored

$color-navBar-BG: rgb(242,242,249); 							// used in various topbars (not core topnav though!)
$border-navBar: 1px solid rgb(225,225,235);				// used in various topbars
$shadow-navBar: 0px 1px 2px 0px rgba(0,0,10,0.04);// used in various topbars

$color-pageBG: rgb(245,244,251); 									// this is the background gray throughout Plickers (e.g. can see "under" sidenav)
$color-pageCenterBorder: rgb(225,225,232); 				// border either side of page-center div

$color-cellBG-zebra: rgb(251,251,255); 						// alternative row color

$cloudBGLightBlue:  rgb(241,247,255); 						// used for cloud SVG in Apply order modal, put here to hope to be reused

$color-pageLoaderElement: rgb(244,244,255); 			// displayed while e.g. images are loading

$colorBG-fullScreen-controls: rgba(26,28,34,0.85);// used a lot in Now Playing as dark semi-transparent background

$color-sectionHome-dividerGray: rgb(220,220,227); // also used in pageHeader, which is why its here

$onboarding-bgColor: rgb(252,252,255); 						// should refactor out, used across a few views
