.cookie-policy .modal-content {
  height: 80vh;
	width: 80vw;

	border-radius: 10px;
	box-sizing: border-box;
  overflow: hidden;
}

.cookiePolicy-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.cookiePolicy-content {
  display: flex;
  flex-direction: column;

  align-items: center;
  box-sizing: border-box;
  padding: 16px;

  text-align: center;
  overflow-y: auto;
  flex: 1;
}

.cookiePolicy-browserList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

// Force some styling on the generated content
.cookiePolicy-generatedList h3,
.cookiePolicy-generatedList h4,
.cookiePolicy-generatedList p,
.cookiePolicy-generatedList div,
.cookiePolicy-generatedList table,
.cookiePolicy-generatedList td,
.cookiePolicy-generatedList tr,
.cookiePolicy-generatedList span {
  color: $black !important;
}

.cookiePolicy-generatedList table {
  table-layout: fixed;
  width: 100%;
}

.cookiePolicy-generatedList table th,
.cookiePolicy-generatedList table td {
  white-space: normal;
}

.cookiePolicy-generatedList table th:nth-child(1),
.cookiePolicy-generatedList table td:nth-child(1) {
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: anywhere;
  width: 15%;
}
.cookiePolicy-generatedList table th:nth-child(2),
.cookiePolicy-generatedList table td:nth-child(2) {
  width: 10%;
}
.cookiePolicy-generatedList table th:nth-child(3),
.cookiePolicy-generatedList table td:nth-child(3) {
  width: 8%;
}
.cookiePolicy-generatedList table th:nth-child(4),
.cookiePolicy-generatedList table td:nth-child(4) {
  width: 10%;
}
.cookiePolicy-generatedList table th:nth-child(5),
.cookiePolicy-generatedList table td:nth-child(5) {
  width: 57%;
}


.cookiePolicy-generatedList a {
  color: $blue !important;
}
